import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import APIPortal from "./api-portal"

import "./api-portals.scss"

const EndpointAPIs = () => (
  <Container fluid className="api-portals">
    <Row noGutters className="max-width">
      <APIPortal
        image="lighthouse"
        title="Lighthouse"
        description="Access information about endpoints, acquisitions, alerts, conditions, indicators, and containment."
        toSpec="/apis/lighthouse/"
        toDocs="/docs/endpoint/"
        lg={{ size: 6 }}
      />
      <APIPortal
        image="messagebus"
        title="Messagebus"
        description="Read access to Messagebus. Used for event communication between Endpoint services and modules."
        toSpec="/apis/messagebus/"
        toDocs="/docs/endpoint/"
        lg={{ size: 6 }}
      />
      <APIPortal
        image="plugin"
        title="Plugin Manager"
        description="Install/uninstall, upgrade/rollback, and enable/disable modules on an Endpoint instance."
        toSpec="/apis/plugin-manager/"
        toDocs="/docs/endpoint/"
        lg={{ size: 6 }}
      />
    </Row>
    <Row noGutters className="max-width">
      <APIPortal
        image="platform"
        title="Platform Service"
        description="Create, read, update, and delete module key configurations."
        toSpec="/apis/platform-service/"
        toDocs="/docs/endpoint/"
        lg={{ size: 6 }}
      />
    </Row>
  </Container>
)

export default EndpointAPIs
