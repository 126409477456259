import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import EndpointAPIs from "../components/endpoint-apis"

const IndexPage = () => (
  <Layout detachedHeader>
    <SEO title="Home" />
    <Hero title={`Endpoint Security APIs`}></Hero>
    <EndpointAPIs />
  </Layout>
)

export default IndexPage
